import Service from "@/services/Service"
import type Report from "@/model/reports/Report"

export default class AgentService extends Service {

	async fetchJoStatus() {
		return await this._fetch(this.data_url + "/agents/running");
	}

	async toggleJob(agentId: string, jobName: string, enabled: boolean): Promise<void> {
		return this._fetch(`${this.data_url}/agents/jobs/toggle`, {
			method: "POST",
			body: {
				'agentId': agentId,
				'jobName': jobName,
				'enabled': enabled
			}
		});
	}

	async fetchReports(date: String): Promise<Report[]> {
		return await this._fetch(`${this.data_url}/reports/agent_fetch/${date.replaceAll('/','-')}`, {
			method: "GET"
		});
	}

}