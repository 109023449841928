import { debouncer } from "@/utilities/ObjectUtils";
import longpress from "@/directives/longpress";

export default defineNuxtPlugin((nuxtApp) => {
	nuxtApp.vueApp.directive('debounced', {
		beforeMount(el, binding) {
			if (binding.arg) {
				const originalHandler = binding.value;
				const delay = parseInt(Object.keys(binding.modifiers)[0], 10);
				const debouncedHandler = debouncer(originalHandler, delay);
				el.addEventListener(binding.arg, debouncedHandler);
			}
		},
		beforeUnmount(el, binding) {
			if (binding.arg) {
				const originalHandler = binding.value;
				const delay = parseInt(Object.keys(binding.modifiers)[0], 10);
				const debouncedHandler = debouncer(originalHandler, delay);
				el.removeEventListener(binding.arg, debouncedHandler);
			}
		}
	});
	nuxtApp.vueApp.directive('longpress', longpress);
});