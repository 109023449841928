
import type { Item } from "./referencedata/Item";
import ItemType from "./referencedata/ItemType";

export default class Order {
  public number: number = 0;
  public state: string = "SELECTING";
  public orderTime: string | undefined;
  public completeTime: string | undefined;
  public rows: Item[] = [];
  public viewOrderNumber: number = 0;
  public deviceNames: string | undefined;
  public tableNumber: string | undefined;

  public completedInSeconds = (): number => {
    if (this.completeTime) {
      const [hours, minutes, seconds] = this.completeTime.split(':').map(Number);
      return (hours * 3600) + (minutes * 60) + seconds;
    } else {
      return 0;
    }
  }

  // Convert the string containing the order time in the format 'HH:mm:ss' in UTC time to a new string containing the order time in the format 'HH:mm:ss' in the local time zone.
  public zonedTime = (): string => {
    const offset = -new Date().getTimezoneOffset();
    const [hours, minutes, seconds] = this.orderTime ? this.orderTime.split(':').map(Number) : [0, 0, 0];
    const localTimeInMinutes = hours * 60 + minutes + offset;
    const localHours = ('0' + Math.floor(localTimeInMinutes / 60)).slice(-2);
    const localMinutes = ('0' + (localTimeInMinutes % 60)).slice(-2);
    const localSeconds = ('0' + seconds).slice(-2);
    return `${localHours}:${localMinutes}:${localSeconds}`;
  }

  public minutesSinceOrder = (): number => {
    const [orderHours, orderMinutes] = this.zonedTime().split(':').map(Number);
    const orderTimeInMinutes = orderHours * 60 + orderMinutes;

    const currentTime = new Date();
    const currentTimeInMinutes = currentTime.getHours() * 60 + currentTime.getMinutes();

    return currentTimeInMinutes - orderTimeInMinutes;
  }

  public removeRow = (itemNumber: number): void => {
    this.rows.forEach((item, index) => {
      if (item.number === itemNumber) this.rows.splice(index, 1);
    });
  }

  public totalPrice = (): number => {
    return this.rows.reduce((total, item) => {
      return total + (item.price * item.numberOfItems);
    }, 0);
  }

  public totalMenuItems = (): number => {
    return this.rows.reduce((total, item) => {
      if (item.itemType === ItemType.MENU_ITEM) {
        return total + item.numberOfItems;
      }
      return total;
    }, 0);
  }

  public totalNonMenuItems = (): number => {
    return this.rows.reduce((total, item) => {
      if (item.itemType !== ItemType.MENU_ITEM) {
        return total + item.numberOfItems;
      }
      return total;
    }, 0);
  }

  public hasItemsToSend = (coolDownTime: number): boolean => {
    let has = (this.totalNonMenuItems() > 0 || this.totalMenuItems() > 0);
    return has && 'SELECTING' == this.state && (coolDownTime <= 0 || this.totalNonMenuItems() > 0);
  }

  getDeviceNames(): string[] {
    if (!this.deviceNames) {
      return [];
    }
    return this.deviceNames
      .split(',')
      .map(name => name.trim())
      .filter(name => name.length > 0);
  }

};
