import Session from "@/model/Session"

export class StoreData {
  public session?: Session
  public clear() {
    Object.assign(this, getDefaultState())
  }
}

export const getDefaultState = () => {
  return Object.assign(new StoreData(), {})
}
