import Coordinate from "@/model/Coordinate";
import Line from "@/model/Line";
import ItemPlaceholder from "@/model/ItemPlaceholder";

export default class Graphics {
  /**
   * Checks if a point is insidePolygon a given polygon
   * @param point is the point to check
   * @param polygon is the polygon it should be insidePolygon
   * @returns true when it's insidePolygon.
   */
  public static insidePolygon(point: number[], polygon: number[][]): boolean {
    const x = point[0];
    const y = point[1];
    let inside = false;
    for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
      const xi = polygon[i][0];
      const yi = polygon[i][1];
      const xj = polygon[j][0];
      const yj = polygon[j][1];
      const intersect =
        yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
      if (intersect) {
        inside = !inside;
      }
    }
    return inside;
  }

  public static insideRadius(
    point1: number[],
    point2: number[],
    radius: number
  ): boolean {
    const A = Math.abs(point1[0] - point2[0]);
    const B = Math.abs(point1[1] - point2[1]);
    const distanceToOffsetCenter = Math.sqrt(Math.pow(A, 2) + Math.pow(B, 2));
    return distanceToOffsetCenter < radius;
  }

  /**
   * Function to calculate the center of mass for a given polygon, according
   * ot the algorithm defined at
   * http://local.wasp.uwa.edu.au/~pbourke/geometry/polyarea/
   *
   * @param polyPoints array of points in the polygon
   * @return point that is the center of mass
   */
  public static centerOfPolygon(polyPoints: number[][]): number[] {
    let cx: number = 0;
    let cy: number = 0;
    let area: number = Graphics.area(polyPoints);
    // could change this to Point2D.Float if you want to use less memory
    const res: number[] = [0, 0];
    let i: number;
    let j: number;
    const n = polyPoints.length;
    let factor: number;
    for (i = 0; i < n; i++) {
      j = (i + 1) % n;
      factor =
        polyPoints[i][0] * polyPoints[j][1] -
        polyPoints[j][0] * polyPoints[i][1];
      cx += (polyPoints[i][0] + polyPoints[j][0]) * factor;
      cy += (polyPoints[i][1] + polyPoints[j][1]) * factor;
    }
    area *= 6.0;
    factor = 1 / area;
    cx *= factor;
    cy *= factor;
    res[0] = cx;
    res[1] = cy;
    return res;
  }

  public static calculatePosition(item: ItemPlaceholder): Coordinate {
    const position = new Coordinate();
    if (item.center) {
      let x = item.center.x;
      let y = item.center.y;
      const offset = item.customConfiguration.offset;
      if (offset) {
        x = x + offset.x;
        y = y + offset.y;
      }
      position.x = x;
      position.y = y;
    }
    return position;
  }

  public static toRatio(
    coordinate: { x: number; y: number },
    ratio: number
  ): { x: number; y: number } {
    return { x: coordinate.x * ratio, y: coordinate.y * ratio };
  }

  public static indexOfEdge(polygon: number[][], line: Line) {
    return (
      polygon.findIndex(
        (coordinate) =>
          line.start.x === coordinate[0] && line.start.y === coordinate[1]
      ) + 1
    );
  }

  public static extractLongest(polygon: number[][]): {
    index: number;
    line: Line;
  } {
    const candidate: Line = new Line();
    const max = polygon.length;
    let maxDistance = 0;
    let index = 0;
    for (let count = 0; count < max; count++) {
      const corner1 = polygon[count];
      let nextIndex = count + 1;
      if (nextIndex > max - 1) {
        nextIndex = 0;
      }
      const corner2 = polygon[nextIndex];
      const line: Line = {
        start: { x: corner1[0], y: corner1[1] },
        end: { x: corner2[0], y: corner2[1] }
      };
      const distance = Graphics.distance(line);
      if (distance > maxDistance) {
        index = count;
        maxDistance = distance;
        candidate.start = { x: corner1[0], y: corner1[1] };
        candidate.end = { x: corner2[0], y: corner2[1] };
      }
    }
    return { index, line: candidate };
  }

  public static distance(line: Line): number {
    let xs = line.end.x - line.start.x;
    let ys = line.end.y - line.start.y;
    xs *= xs;
    ys *= ys;
    return Math.sqrt(xs + ys);
  }

  public static middle(line: Line): Coordinate {
    return {
      x: line.start.x + (line.end.x - line.start.x) * 0.5,
      y: line.start.y + (line.end.y - line.start.y) * 0.5
    };
  }

  private static area(polyPoints: number[][]): number {
    let i: number;
    let j: number;
    const n = polyPoints.length;
    let area = 0;

    for (i = 0; i < n; i++) {
      j = (i + 1) % n;
      area += polyPoints[i][0] * polyPoints[j][1];
      area -= polyPoints[j][0] * polyPoints[i][1];
    }
    area /= 2.0;
    return area;
  }
}

/**
 * Adding lazy image loading hook
 */
let lazyloadThrottleTimeout: any;

export const initLazyLoading = () => {
  let lazyloadImages: NodeListOf<Element>;

  if ("IntersectionObserver" in window) {
    lazyloadImages = document.querySelectorAll(".lazy");
    const imageObserver = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const image = entry.target as HTMLImageElement;
          image.src =
            image.dataset.src !== undefined ? image.dataset.src : "unknown";
          image.classList.remove("lazy");
          imageObserver.unobserve(image);
        }
      });
    });

    lazyloadImages.forEach((image) => {
      imageObserver.observe(image);
    });
  } else {
    lazyloadImages = document.querySelectorAll(".lazy");

    function lazyload() {
      if (lazyloadThrottleTimeout) {
        clearTimeout(lazyloadThrottleTimeout);
      }

      lazyloadThrottleTimeout = setTimeout(() => {
        const scrollTop = window.pageYOffset;
        lazyloadImages.forEach((img) => {
          const htmlImageElement = img as HTMLImageElement;
          if (htmlImageElement.offsetTop < window.innerHeight + scrollTop) {
            htmlImageElement.src =
              htmlImageElement.dataset.src !== undefined
                ? htmlImageElement.dataset.src
                : "unkown";
            img.classList.remove("lazy");
          }
        });
        if (lazyloadImages.length === 0) {
          document.removeEventListener("scroll", lazyload);
          window.removeEventListener("resize", lazyload);
          window.removeEventListener("orientationChange", lazyload);
        }
      }, 20);
    }

    document.addEventListener("scroll", lazyload);
  }
};

export const moveElementToBody = (elementId: string) => {
  // Get the existing element by ID
  const element = document.getElementById(elementId);

  if (element) {
    // Remove the element from its original parent
    const originalParent = element.parentNode;
    if (originalParent) {
      originalParent.removeChild(element);
    }
    // Append the element to the body
    document.body.appendChild(element);
  }
}

export const urlForLarge = (imageName: string) => {
  const licenseCode = services.storageService().data().session?.licenseCode || "no_license"
  return encodeURI("/api/static/" + licenseCode + "/400px_" + imageName.replaceAll(' ', '-'))
}

export const urlForSmall = (imageName: string) => {
  const licenseCode = services.storageService().data().session?.licenseCode || "no_license"
  return encodeURI("/api/static/" + licenseCode + "/100px_" + imageName.replaceAll(' ', '-'))
}

export const urlForOriginal = (imageName: string) => {
  const licenseCode = services.storageService().data().session?.licenseCode || "no_license"
  return encodeURI("/api/static/" + licenseCode + "/" + imageName.replaceAll(' ', '-'))
}

export const urlForContent = () => {
  const licenseCode = services.storageService().data().session?.licenseCode || "no_license"
  return encodeURI("/api/static/" + licenseCode)
}

export const swapDivs = async (id1: string, id2: string) => {
  const div1 = document.getElementById(id1)!!;
  const div2 = document.getElementById(id2)!!;

  const rect1 = div1.getBoundingClientRect();
  const rect2 = div2.getBoundingClientRect();

  const temp = document.createElement('div');
  temp.style.visibility = 'hidden';
  temp.style.position = 'absolute';
  temp.style.width = rect1.width + 'px';
  temp.style.height = rect1.height + 'px';

  const parent = div1.parentNode!!;
  const parent2 = div2.parentNode!!;
  if (parent2 !== parent) {
    throw new Error('Elements have different parents');
  }
  parent.insertBefore(temp, div1);
  parent.insertBefore(div1, div2);
  parent.insertBefore(div2, temp);
  parent.removeChild(temp);

  // Trigger animation by changing the transform property
  div1.style.transform = `translate(${rect1.left - rect2.left}px, ${rect1.top - rect2.top}px)`;

  div2.style.transform = `translate(${rect2.left - rect1.left}px, ${rect2.top - rect1.top}px)`;
  // Clear the transform property after the animation completes
  setTimeout(() => {
    div1.style.transform = '';
    div2.style.transform = '';
  }, 100);
}

/**
 * Scrolls an element with the specified ID into view with customizable options
 * @param elementId - The ID of the element to scroll to
 * @param yOffset - Vertical offset in pixels (positive values scroll lower, negative values scroll higher)
 * @param options - Optional ScrollIntoViewOptions configuration
 * @returns Promise that resolves when the scroll is complete (or immediately if element not found)
 */
export const scrollToElement = (
  elementId: string,
  yOffset: number = 0,
  options: ScrollIntoViewOptions = { behavior: 'smooth', block: 'start' }
): Promise<void> => {
  return new Promise((resolve) => {
    const element = document.getElementById(elementId);

    if (!element) {
      console.warn(`Element with ID "${elementId}" not found`);
      resolve();
      return;
    }

    // Calculate position with offset
    const elementPosition = element.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.pageYOffset + yOffset;

    // Custom scrolling to apply the offset
    window.scrollTo({
      top: offsetPosition,
      behavior: options.behavior || 'smooth'
    });

    // Approximate time to complete scroll animation for promise resolution
    if (options.behavior === 'smooth') {
      setTimeout(resolve, 500);
    } else {
      resolve();
    }
  });
};
