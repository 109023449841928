import { getDefaultState } from "@/services/storage/StoreData"
import AutorizationService from "@/services/AuthorizationService"
import StorageService from "@/services/storage/StorageService"
import DevicesService from "@/services/DevicesService"
import ClientService from "@/services/ClientService"
import TableService from "@/services/TableService"
import LocationService from "@/services/LocationService"
import ReferenceDataService from "@/services/ReferenceDataService"
import ResourcesService from "@/services/ResourcesService"
import AgentService from "~/services/AgentService"

export const services = {
  tableService: (): TableService => {
    return {} as TableService
  },
  storageService: (): StorageService => {
    return {} as StorageService
  },
  resourcesService: (): ResourcesService => {
    return {} as ResourcesService
  },
  authorizationService: (): AutorizationService => {
    return {} as AutorizationService
  },
  devicesService: (): DevicesService => {
    return {} as DevicesService
  },
  clientService: (): ClientService => {
    return {} as ClientService
  },
  locationService: (): LocationService => {
    return {} as LocationService
  },
  referenceDataService: (): ReferenceDataService => {
    return {} as ReferenceDataService
  },
  agentService: (): AgentService => {
    return {} as AgentService
  }
}

// Clientside plugins
export default defineNuxtPlugin((nuxtApp) => {
  if (import.meta.client) {
    const localStorage = new StorageService()
    localStorage.init(() => getDefaultState())
    nuxtApp.store = localStorage
    nuxtApp.services = services
    services.storageService = () => localStorage
    services.authorizationService = () => new AutorizationService(localStorage)
    services.devicesService = () => new DevicesService(localStorage)
    services.clientService = () => new ClientService(localStorage)
    services.tableService = () => new TableService(localStorage)
    services.locationService = () => new LocationService(localStorage)
    services.referenceDataService = () => new ReferenceDataService(localStorage)
    services.resourcesService = () => new ResourcesService(localStorage)
    services.agentService = () => new AgentService(localStorage)

  }
  return {
    provide: {
      services,
      store: localStorage
    }
  }
})
