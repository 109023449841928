<template>
  <vLayout>
    <div id="avatar_overlay" :class="showAvatarCard ? 'avatarCardShowing' : ''" @click="() => {
      showAvatarCard = false
      showChangePassword = false
    }
    "></div>
    <div id="alerts">
      <VAlert v-model="hasError" type="error" elevation="2" colored>
        {{ error }}
      </VAlert>
      <VAlert v-model="hasWarning" type="warning" elevation="2" colored>
        {{ warning }}
      </VAlert>
      <VAlert v-model="hasMessage" type="info" elevation="2" colored>
        {{ message }}
      </VAlert>
    </div>

    <VApp :class="!isLoggedIn ? 'landing' : ''">
      <VAppBar height="45" elevation="8" fixed style="position: fixed">
        <VAppBarNavIcon @click="() => {
          if (isLoggedIn) drawer = !drawer
        }
        "></VAppBarNavIcon>
        <div id="page_title"></div>
        <VSpacer></VSpacer>
        <div id="appbar_content"></div>
        <VSpacer></VSpacer>

        <span v-if="isLoggedIn && !isClient" @click="toggleAvatarCard">
          {{ session?.user?.username }} {{ session?.user?.oauthUser ? "*" : "" }}
          <VIcon icon="mdi-account-box" rounded size="28"
            :color="systemAccount ? 'red' : isAdmin ? '#6464aa' : 'white'" />
        </span>
        <span class="mdi mdi-circle" style="color: green" v-if="connectionState"></span>
        <span class="mdi mdi-circle blink" style="color: red;" v-if="!connectionState && isLoggedIn"></span>
      </VAppBar>

      <VNavigationDrawer v-model="drawer" style="position: fixed">
        <VList nav dense v-if="isLoggedIn && !isClient">
          <VListItem @click="navigateTo('/dashboard')" v-if="!systemAccount">
            <VIcon>mdi-monitor-dashboard</VIcon>
            <VListItemTitle>{{ $t("dashboard-0") }}</VListItemTitle>
          </VListItem>
          <VListItem @click="navigateTo('/admin/licenses')" v-if="systemAccount || isAdmin">
            <VIcon>mdi-folder-multiple-outline</VIcon>
            <VListItemTitle>{{ $t("licenses") }}</VListItemTitle>
          </VListItem>
          <VListItem @click="navigateTo('/admin/users')" v-if="systemAccount || isAdmin">
            <VIcon>mdi-account</VIcon>
            <VListItemTitle>{{ $t("users") }}</VListItemTitle>
          </VListItem>
          <VListItem @click="navigateTo('/admin/ordersoverview')">
            <VIcon>mdi-folder-multiple-outline</VIcon>
            <VListItemTitle>{{ $t("ordersoverview") }}</VListItemTitle>
          </VListItem>
          <VListItem @click="navigateTo('/admin/agents')" v-if="systemAccount || isAdmin">
            <VIcon icon="custom:Robot" />
            <VListItemTitle>{{ $t("agents") }}</VListItemTitle>
          </VListItem>
          <VListItem @click="navigateTo('/admin/apikeys')" v-if="!systemAccount && isAdmin">
            <VIcon>mdi-key-chain</VIcon>
            <VListItemTitle>{{ $t("api-keys") }}</VListItemTitle>
          </VListItem>
          <VListItem @click="navigateTo('/admin/configurations/content')" v-if="!systemAccount && isAdmin">
            <VIcon>mdi-folder-multiple-outline</VIcon>
            <VListItemTitle>{{ $t("content") }}</VListItemTitle>
          </VListItem>
          <VListItem @click="navigateTo('/admin/resources')" v-if="!systemAccount && isAdmin">
            <VIcon>mdi-folder-multiple-outline</VIcon>
            <VListItemTitle>{{ $t("resources") }}</VListItemTitle>
          </VListItem>
          <VListItem @click="navigateTo('/admin/configurations')" v-if="!systemAccount && isAdmin">
            <VIcon>mdi-view-list</VIcon>
            <VListItemTitle>{{ $t("configurations") }}</VListItemTitle>
          </VListItem>
          <VListItem v-for="location in locations" v-if="!systemAccount && !isAdmin"
            @click="navigateTo({ path: '/tables', query: { locationName: location.name } })">
            <VIcon>mdi-folder-multiple-outline</VIcon>
            <VListItemTitle>
              {{ $t("tables-location-name", [location.name]) }}
            </VListItemTitle>
          </VListItem>
          <VListItem v-if="!systemAccount" @click="navigateTo({ path: '/reviews' })">
            <VIcon>mdi-note-check</VIcon>
            <VListItemTitle>{{ $t("reviews") }}</VListItemTitle>
          </VListItem>
        </VList>
        <VDivider></VDivider>
        <div id="client_order"></div>
        <VDivider></VDivider>
        <div id="client_menu"></div>
        <VDivider></VDivider>
        <div>
          <v-select v-model="locale" :items="locales" itemText="title" itemValue="value" :label="$t('locale')"
            hide-details @update:model-value="updateLocale"></v-select>
          <v-select v-model="currentTheme" :items="themes" :label="$t('theme')" itemText="title" itemValue="value"
            hide-details @update:model-value="themeUpdated"></v-select>
        </div>
        <VDivider></VDivider>
        <VExpansionPanels v-if="session?.clientSession">
          <VExpansionPanel>
            <VExpansionPanelTitle>{{ $t("add-a-device") }}</VExpansionPanelTitle>
            <VExpansionPanelText>
              <QRCodeVue3 :width="150" :height="150" :value="bindingUrl() ?? ''" :dotsOptions="{ type: 'square' }" />
            </VExpansionPanelText>
          </VExpansionPanel>
        </VExpansionPanels>
      </VNavigationDrawer>
      <VMain id="main">
        <LazyNuxtPage></LazyNuxtPage>
      </VMain>
    </VApp>

    <VCard @mouseenter.native="clearAvatarCardTimeout" @mouseleave.native="startAvatarCardTimeout" style="z-index: 3333"
      id="avatar_card" v-if="isLoggedIn && showAvatarCard">
      <VCardTitle>{{ session?.user?.username }}</VCardTitle>
      <VCardText>
        <div>{{ $t("license-session-licensecode", [session?.licenseCode]) }}</div>
        <div>{{ $t("role-session-user-roles", [session?.user?.roles]) }}</div>
        <VForm id="passwordChangeForm" v-if="showChangePassword">
          <VTextField v-model="username" type="text" hidden autocomplete="username" style="display: none"></VTextField>
          <VTextField v-model="oldPassword" type="password" :label="$t('old-password')" autocomplete="current-password"
            @change="tryUpdatePassword"></VTextField>
          <VTextField v-model="newPassword" type="password" :label="$t('new-password')"
            :rules="[sameIfFilled(newPassword, repeatedPassword)]" autocomplete="new-password"
            @change="tryUpdatePassword"></VTextField>
          <VTextField v-model="repeatedPassword" type="password" :rules="[sameIfFilled(newPassword, repeatedPassword)]"
            autocomplete="new-password" :label="$t('repeat')" @change="tryUpdatePassword"></VTextField>
        </VForm>
      </VCardText>
      <VCardActions>
        <VBtn color="red" @click="logout()" prepend-icon="mdi-logout">LOGOUT</VBtn>
        <VBtn @click="changePassword()" prepend-icon="mdi-lock-reset" v-if="!session?.user?.oauthUser">
          {{ $t("change-password") }}
        </VBtn>
        <VBtn @click="showTransferButton" prepend-icon="mdi-account-convert" v-if="!systemAccount && !isAdmin">
          {{ $t("transfer-account") }}
        </VBtn>
      </VCardActions>
    </VCard>
  </vLayout>
  <TransferCodeDialog v-model="showTransferDialog" />

</template>

<style scoped>
/* To hide the notch */
/* To hide the notch in portrait mode */
.v-application {
  padding: env(safe-area-inset-top);
}

#menu_buttons {
  bottom: 40px;
  width: 100%;
  z-index: 4000;
  background-color: rgb(83, 83, 83);
}

#menu_buttons button {
  margin-bottom: 15px;
}

#alerts {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 4000;
}

#avatar_overlay {
  display: none;
}

#avatar_overlay.avatarCardShowing {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3332;
  display: block;
}

#avatar_card {
  position: fixed;
  border: 2px solid rgb(147, 147, 147);
  z-index: 1000;
  top: 35px;
  right: 10px;
}

#main {
  overflow: auto;
}

.v-footer {
  position: fixed;
  color: rgb(122, 122, 122);
  font-size: 10px;
  text-overflow: ellipsis;
}

/* To hide the notch in landscape mode */
@media (orientation: landscape) {
  .v-application {
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
  }
}

.landing {
  background-image: url("/images/background.webp");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}

/* To prevent the non-useable parts from being clicked */
.v-application {
  user-select: none;
}
</style>

<script lang="ts" setup>
import Location from "@/model/Location"
import eventBus, { EVENT_ERROR, EVENT_MESSAGE, EVENT_SESSION_INVALIDATED, EVENT_WARNING } from "@/services/EventBus"
import tracker, { setUserId } from "@/services/OpenReplay"
import { moveElementToBody } from "@/utilities/Graphics"
import { safeMap } from "@/utilities/ObjectUtils"
import type ConnectionListener from "@/websocket/ConnectionListener"
import socketService from "@/websocket/SocketService"
import Cookies from "js-cookie"
import QRCodeVue3 from "qrcode-vue3"
import { useTheme } from "vuetify"
import "vuetify/dist/vuetify.min.css"
import { VApp } from "vuetify/lib/components/index.mjs"
import Session from "./model/Session"
import { navigateTo } from "#app"

if (import.meta.client && process.env.NODE_ENV === "production") {
  tracker.start()
}

const messagesDisplayTime = 2500

let { authorizationService, storageService } = services

const error = ref("")
const warning = ref("")
const message = ref("")
const oldPassword = ref("")
const newPassword = ref("")
const repeatedPassword = ref("")
const username = computed(() => {
  session.value?.user?.username
});

const showChangePassword = ref(false)
const hasError = computed(() => error.value !== "" && error.value !== undefined)
const hasWarning = computed(() => warning.value !== "" && warning.value !== undefined)
const hasMessage = computed(() => message.value !== "" && message.value !== undefined)
const showTransferDialog = ref(false);
let errorTimeout: any = undefined
let warningTimeout: any = undefined
let messageTimeout: any = undefined
let avatarCardTimeout: any = undefined
let $t = useI18n().t
let { changeLocale } = useVuetify()

const theme = useTheme()
const toThemeEntry = (themeName: string) => {
  return {
    title: themeName.charAt(0).toUpperCase() + themeName.slice(1),
    value: themeName,
  }
}

let currentTheme: Ref<string> = ref("")
let availableThemes: string[] = Object.keys(theme.themes.value)

let locale: Ref<string> = ref("")
interface LocaleEntry {
  title: string
  value: string
}
let locales: Ref<LocaleEntry[]> = ref([])
const themes = availableThemes.map((themeName) => {
  return toThemeEntry(themeName)
})

let systemAccount = computed<boolean>(() => {
  updated.value
  const licenseCode = session.value?.licenseCode
  return licenseCode === "system"
})

let isAdmin = computed<boolean>(() => {
  updated.value
  return authorizationService().findRole("Admin group") !== undefined
})

let showTransferButton = () => {
  showTransferDialog.value = true;
  showAvatarCard.value = false;
}

let isClient = computed<boolean>(() => {
  updated.value
  return session.value?.clientSession || false
})

let isLoggedIn = computed<boolean>(() => {
  updated.value
  return session.value?.apiKey !== undefined
})

const updated = ref(1)
const showAvatarCard = ref(false)
const drawer = ref(false)
const locations = ref<Location[]>([])
const connectionState = ref(false)
let i18n = useI18n()

let session: Ref<Session | undefined> = ref(storageService().data(false).session);

onBeforeMount(async () => {
  // First check if the session in the local storage is still valid.
  let existingSession = storageService().data(false).session
  if (existingSession && existingSession?.apiKey) {
    try {
      // Check if session id is still valid. If not invalidate and logout the app
      await authorizationService().retrieveSession(existingSession.apiKey!!);
    } catch (error: any) {
      if (error.statusCode == 401) {
        authorizationService().logout()
        navigateTo("/")
      }
    }
  }
})

onMounted(async () => {
  let themeLoaded = Cookies.get("ordernow_theme")
  if (themeLoaded) {
    currentTheme.value = themeLoaded
    theme.global.name.value = themeLoaded
  } else {
    currentTheme.value = theme.global.name.value
  }

  i18n.availableLocales.forEach((locale) => {
    locales.value.push({ value: locale, title: i18n.t(`locale.${locale}`) })
  })
  if (session.value && session.value?.locale) {
    let sessionLocale = session.value?.locale!!
    if (sessionLocale.length > 2) {
      locale.value = sessionLocale.substring(0, 2)
    } else {
      locale.value = sessionLocale
    }
  } else {
    locale.value = "en"
  }

  setLocale(locale.value)
  if (session.value && session.value?.apiKey) {
    socketService
      .configure(
        session.value?.apiKey,
        {
          status: (connected: boolean, message: string) => {
            eventBus.value.emit("event:connection_status", connected, message)
          },
        } as ConnectionListener
      )
      .init()
  }
  menuUpdate()
  eventBus.value.on("event:menu_update", menuUpdate)
  eventBus.value.on("event:connection_status", connectionStateChange)
  eventBus.value.on("event:sessionUpdated", () => {
    session.value = storageService().data(false).session;
  })
  eventBus.value.on(EVENT_MESSAGE, showMessage)
  eventBus.value.on(EVENT_WARNING, showWarning)
  eventBus.value.on(EVENT_ERROR, showError)
  eventBus.value.on(EVENT_SESSION_INVALIDATED, (_: string) => {
    showError("Authorization Failed")
    setTimeout(() =>
      navigateTo('/'), 3000)
  })
  moveElementToBody("alerts")

  setUserId(
    session.value?.apiKey?.toString() || session.value?.user?.username?.toString() || "anonymous"
  )
})

const showError = (aMessage: string) => {
  if (!error.value.includes(aMessage)) {
    error.value = error.value + aMessage + "\n"
  }
  if (errorTimeout) clearTimeout(errorTimeout)
  errorTimeout = setTimeout(() => {
    error.value = ""
  }, messagesDisplayTime)
}

const themeUpdated = (themeName: string) => {
  theme.global.name.value = themeName
  Cookies.set("ordernow_theme", themeName)
}

const sameIfFilled = (a: string, b: string) => {
  return a === b || a === "" || b === "" ? true : $t("passwords-do-not-match")
}

const setLocale = (localeCode: string) => {
  if (localeCode == "zh") {
    changeLocale("zhHans")
  } else {
    changeLocale(localeCode)
  }
  i18n.setLocale(localeCode)
}

/**
 * Tries to update the password if old password is filled and the new password is filled and the repeated password is filled and the new password is the same as the repeated password.
 * Also the new password and the repeated password are not the same.
 * The new password is not the same as the old password.
 * If the password is updated, the form is reset.
 */
const tryUpdatePassword = async () => {
  if (
    oldPassword.value !== "" &&
    newPassword.value !== "" &&
    repeatedPassword.value !== "" &&
    newPassword.value === repeatedPassword.value &&
    newPassword.value !== oldPassword.value
  ) {
    let updated = await authorizationService().updatePassword(
      oldPassword.value,
      newPassword.value
    )
    if (updated === $t("password-updated")) {
      showMessage($t("password-updated"))
    } else {
      showError($t("password-not-updated"))
    }
    closeAvatarCard()
  }
}

const showMessage = (aMessage: string) => {
  if (!message.value.includes(aMessage)) {
    message.value = message.value + aMessage + "\n"
  }
  if (messageTimeout) clearTimeout(messageTimeout)
  messageTimeout = setTimeout(() => {
    message.value = ""
  }, messagesDisplayTime)
}

const changePassword = () => {
  showChangePassword.value = !showChangePassword.value
}

const clearAvatarCardTimeout = () => {
  if (avatarCardTimeout) clearTimeout(avatarCardTimeout)
}

const startAvatarCardTimeout = () => {
  if (avatarCardTimeout) clearTimeout(avatarCardTimeout)
  if (showAvatarCard.value) {
    avatarCardTimeout = setTimeout(() => {
      closeAvatarCard()
    }, 3000)
  }
}

const closeAvatarCard = () => {
  showAvatarCard.value = false
  showChangePassword.value = false
  oldPassword.value = ""
  newPassword.value = ""
  repeatedPassword.value = ""
}

const showWarning = (aMessage: string) => {
  if (!warning.value.includes(aMessage)) {
    warning.value = warning.value + aMessage + "\n"
  }
  if (warningTimeout) clearTimeout(warningTimeout)
  setTimeout(() => {
    warning.value = ""
  }, messagesDisplayTime)
}

const connectionStateChange = (connected: boolean, message: string) => {
  connectionState.value = connected
}

const toggleAvatarCard = () => {
  showAvatarCard.value = !showAvatarCard.value
  if (showAvatarCard.value) {
    startAvatarCardTimeout()
  }
}

async function menuUpdate() {
  const loggedIn = session.value?.apiKey !== undefined
  const isClient = session.value?.clientSession
  if (loggedIn && !isClient) {
    let locationUserMap = session.value?.user?.locationUserMap
    if (locationUserMap) {
      locations.value = await services.locationService().fetchLocations()!!
      if (locations.value.length > 0) {
        let locationUserMap = safeMap(session.value?.user?.locationUserMap)
        if (locationUserMap) {
          // Filter locations.value to only include locations that are in the locationUserMap with a usernumber > 0
          locations.value = locations.value.filter((location) => {
            return locationUserMap.get(location.name) > 0
          })
        }
      }
    }
  }
  updated.value++
}


function bindingUrl() {
  return session.value?.bindingUrl()
}

const updateLocale = async (newLocale: string) => {
  await authorizationService().setLocaleForSession(newLocale)
  setLocale(newLocale)
}

const logout = () => {
  authorizationService().logout()
  menuUpdate()
  navigateTo("/")
}
</script>
