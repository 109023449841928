// composables/useVuetify.ts
import { computed } from 'vue'

export const useVuetify = () => {
	const { $vuetify } = useNuxtApp()

	const changeLocale = (newLocale: string) => {
		if ($vuetify && $vuetify.locale) {
			$vuetify.locale.current.value = newLocale
		}
	}

	const currentLocale = computed(() => $vuetify?.locale?.current || 'en')

	return {
		changeLocale,
		currentLocale
	}
}