/**
 * Simple object check.
 * @param item
 * @returns {boolean}
 */
export default class ObjectUtils {

  /**
   * Deep merge two objects.
   * @param target
   * @param sources
   */
  public static mergeDeep(target: any, ...sources: any[]): any {
    if (!sources.length) {
      return target;
    }
    const source = sources.shift();
    if (ObjectUtils.isObject(target) && ObjectUtils.isObject(source)) {
      for (const key in source) {
        if (ObjectUtils.isObject(source[key])) {
          if (!target[key]) {
            Object.assign(target, { [key]: {} });
          }
          ObjectUtils.mergeDeep(target[key], source[key]);
        } else {
          Object.assign(target, { [key]: source[key] });
        }
      }
    }
    return ObjectUtils.mergeDeep(target, ...sources);
  }

  public static secondsBetween(start: string, end: string): number {
    if (!start || !end) return 0;
    let startSeconds = this.secondsSinceMidnight(start);
    let endSeconds = this.secondsSinceMidnight(end);
    if (endSeconds <= startSeconds) return 0;
    return endSeconds - startSeconds;
  }

  public static secondsSinceMidnight(time24h: string): number {
    if (!time24h || time24h.length < 8) return 0;
    const [hours, minutes, seconds] = time24h.split(':').map(Number);
    return (hours * 3600) + (minutes * 60) + seconds;
  }


  public static findFirstDifferentIndex(arr1: string[], arr2: string[]): number {
    const minLength = Math.min(arr1.length, arr2.length);

    for (let i = 0; i < minLength; i++) {
      if (arr1[i] !== arr2[i]) {
        return i;
      }
    }

    // If all elements up to minLength are identical,
    // but one array is shorter than the other, return the length of the shorter array.
    return minLength;
  }


  /**
   * Creates a deep clone of an object.
   * @param obj The object to clone.
   * @returns The cloned object.
   */
  public static deepClone(obj: any): any {
    if (typeof obj !== 'object' || obj === null) {
      return obj;
    }

    let clone = {} as any;
    for (let key in obj) {
      let value = obj[key];
      clone[key] = ObjectUtils.deepClone(value);
    }
    return clone;
  }

  public static deepCloneWithJSON(obj: any): any {
    return JSON.parse(JSON.stringify(obj));
  }

  /**
   * Reverse the array
   * @param input the array to reverse
   * @return the reversed array
   */
  public static reverseArr(input: any[]): any[] {
    const ret = [];
    for (let i = input.length - 1; i >= 0; i--) {
      ret.push(input[i]);
    }
    return ret;
  }

  /**
   * Sort an array by with a optional function that provides the value from the items to sort by.
   */
  public static sortArray<T>(inputArray: T[], orderBy: (item: T) => any | undefined): T[] {
    return inputArray.sort((a, b) => {
      const aVal = orderBy ? orderBy(a) : a;
      const bVal = orderBy ? orderBy(b) : b;
      if (aVal === undefined && bVal === undefined) {
        return 0;
      } else if (aVal === undefined) {
        return 1;
      } else if (bVal === undefined) {
        return -1;
      } else {
        return aVal < bVal ? -1 : 1;
      }
    });
  }

  public static duplicate(input: any): any {
    const inputJson = JSON.stringify(input);
    return JSON.parse(inputJson);
  }

  public static groupBy(array: any[], key: string) {
    // Return the end result
    return array.reduce((result, currentValue) => {
      // If an array already present for key, push it to the array. Else create an array and push the object
      (result[currentValue[key]] = result[currentValue[key]] || []).push(
        currentValue
      );
      // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
      return result;
    }, {}); // empty object is the initial value for result object
  }

  public static max(items: any, prop: string) {
    if (items == null) {
      return 0;
    }
    let max = 0;
    for (let i = 0, len = items.length; i < len; i++) {
      const val = items[i][prop];
      if (val > max) {
        max = val;
      }
    }
    return max;
  }

  public static fillReferences(object: any) {
    let objectMap: Record<string, any> = {};
    // First pass, find all the references
    ObjectUtils.visit(object, (key, value, parent) => {
      if (key === "_reference_") {
        objectMap[value] = parent;
      }
    });
    // Second pass, replace all the references
    ObjectUtils.visit(object, (key, value, parent) => {
      if (value !== null && typeof value === "string") {
        let referencedObject = objectMap[value];
        if (referencedObject && key !== "_reference_") {
          parent[key] = referencedObject;
        }
      }
    });
    return object;
  }



  public static visit(
    obj: Record<string, any>,
    visitorFunction: (
      key: string,
      value: any,
      parent: Record<string, any>
    ) => void,
    visitedObjects: Set<any> = new Set()
  ): void {
    visitedObjects.add(obj);
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];
        if (typeof value === "object" && value !== null) {
          if (!visitedObjects.has(value)) {
            this.visit(value, visitorFunction, visitedObjects);
          }
        } else {
          visitorFunction(key, value, obj);
        }
      }
    }
  }

  private static isObject(item: any) {
    return item && typeof item === "object" && !Array.isArray(item);
  }

  public static deepDiffMapper() {
    return {
      VALUE_CREATED: 'created',
      VALUE_UPDATED: 'updated',
      VALUE_DELETED: 'deleted',
      VALUE_UNCHANGED: 'unchanged',
      map: function (obj1: any, obj2: any) {
        if (this.isFunction(obj1) || this.isFunction(obj2)) {
          throw 'Invalid argument. Function given, object expected.';
        }
        if (this.isValue(obj1) || this.isValue(obj2)) {
          return {
            type: this.compareValues(obj1, obj2),
            data: obj1 === undefined ? obj2 : obj1
          };
        }

        var diff = {} as any;
        for (var key in obj1) {
          if (this.isFunction(obj1[key])) {
            continue;
          }

          var value2 = undefined;
          if (obj2[key] !== undefined) {
            value2 = obj2[key];
          }

          diff[key] = this.map(obj1[key], value2);
        }
        for (var key in obj2) {
          if (this.isFunction(obj2[key]) || diff[key] !== undefined) {
            continue;
          }

          diff[key] = this.map(undefined, obj2[key]);
        }

        return diff;

      },
      compareValues: function (value1: any, value2: any) {
        if (value1 === value2) {
          return this.VALUE_UNCHANGED;
        }
        if (this.isDate(value1) && this.isDate(value2) && value1.getTime() === value2.getTime()) {
          return this.VALUE_UNCHANGED;
        }
        if (value1 === undefined) {
          return this.VALUE_CREATED;
        }
        if (value2 === undefined) {
          return this.VALUE_DELETED;
        }
        return this.VALUE_UPDATED;
      },
      isFunction: function (x: any) {
        return Object.prototype.toString.call(x) === '[object Function]';
      },
      isArray: function (x: any) {
        return Object.prototype.toString.call(x) === '[object Array]';
      },
      isDate: function (x: any) {
        return Object.prototype.toString.call(x) === '[object Date]';
      },
      isObject: function (x: any) {
        return Object.prototype.toString.call(x) === '[object Object]';
      },
      isValue: function (x: any) {
        return !this.isObject(x) && !this.isArray(x);
      }
    }
  };

}

export const toNumber = (value: string | number) => {
  if (typeof value === 'number') {
    return value
  }
  return Number(value)
}


/**
 * Convert the object if its a Map else return a new Map with key values from the object
 * The keys and values are extracted using Objects.keys and Object.values
 * @param anyObject
 */
export const safeMap = (anyObject: any) => {
  if (anyObject instanceof Map) {
    return anyObject;
  } else if (anyObject) {
    const keys = Object.keys(anyObject);
    const values = Object.values(anyObject);
    return new Map(keys.map((key, index) => [key, values[index]]));
  }
  return new Map();
}

let timeoutIDMap: Map<string, number | null> = new Map();

export const debouncer = <T>(fn: (...args: any[]) => T, delay: number, debounceKey: string | undefined = undefined): (...args: any[]) => void | T => {
  let key = debounceKey !== undefined ? debounceKey : fn.toString();
  let timeoutID = timeoutIDMap.get(key);
  return function (...args: any[]): void | T {
    if (timeoutID) {
      clearTimeout(timeoutID);
    }
    timeoutIDMap.set(key, window.setTimeout(() => {
      try {
        return fn(...args);
      } finally {
        timeoutIDMap.delete(key);
      }
    }, delay));
  };
}

export const asyncDebouncer = <T>(fn: (...args: any[]) => Promise<T>, delay: number, debounceKey: string | undefined = undefined): (...args: any[]) => Promise<void | T> => {
  let key = debounceKey !== undefined ? debounceKey : fn.toString();
  let timeoutID = timeoutIDMap.get(key);
  return async function (...args: any[]): Promise<void | T> {
    if (timeoutID) {
      clearTimeout(timeoutID);
    }
    return new Promise<void | T>((resolve, reject) => {
      timeoutIDMap.set(key, window.setTimeout(async () => {
        try {
          const result = await fn(...args);
          resolve(result);
        } catch (error) {
          reject(error);
        } finally {
          timeoutIDMap.delete(key);
        }
      }, delay));
    });
  };
}
