<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" id="robot">
    <path
      fill="currentColor}"
      d="M16 7.5c-.3 0-.5-.2-.5-.5V4c0-.3.2-.5.5-.5s.5.2.5.5v3c0 .3-.2.5-.5.5z"
    ></path>
    <path
      fill="currentColor"
      d="M16 4.5c-.8 0-1.5-.7-1.5-1.5s.7-1.5 1.5-1.5 1.5.7 1.5 1.5-.7 1.5-1.5 1.5zm0-2c-.3 0-.5.2-.5.5s.2.5.5.5.5-.2.5-.5-.2-.5-.5-.5zm-3 10c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm0-3c-.6 0-1 .4-1 1s.4 1 1 1 1-.4 1-1-.4-1-1-1zm6 3c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm0-3c-.6 0-1 .4-1 1s.4 1 1 1 1-.4 1-1-.4-1-1-1z"
    ></path>
    <path
      fill="currentColor"
      d="M19.5 14.5h-7C10.6 14.5 9 12.9 9 11v-1c0-1.9 1.6-3.5 3.5-3.5h7c1.9 0 3.5 1.6 3.5 3.5v1c0 1.9-1.6 3.5-3.5 3.5zm-7-7C11.1 7.5 10 8.6 10 10v1c0 1.4 1.1 2.5 2.5 2.5h7c1.4 0 2.5-1.1 2.5-2.5v-1c0-1.4-1.1-2.5-2.5-2.5h-7z"
    ></path>
    <path
      fill="currentColor"
      d="M17.5 17.5h-3c-.3 0-.5-.2-.5-.5v-3c0-.3.2-.5.5-.5h3c.3 0 .5.2.5.5v3c0 .3-.2.5-.5.5zm-2.5-1h2v-2h-2v2z"
    ></path>
    <path
      fill="currentColor"
      d="M22.5 28.5h-13c-.8 0-1.5-.7-1.5-1.5v-9c0-.8.7-1.5 1.5-1.5h13c.8 0 1.5.7 1.5 1.5v9c0 .8-.7 1.5-1.5 1.5zm-13-11c-.3 0-.5.2-.5.5v9c0 .3.2.5.5.5h13c.3 0 .5-.2.5-.5v-9c0-.3-.2-.5-.5-.5h-13zm-2 13c-.3 0-.5-.2-.5-.5 0-.8-.7-1.5-1.5-1.5S4 29.2 4 30c0 .3-.2.5-.5.5S3 30.3 3 30c0-1.4 1.1-2.5 2.5-2.5S8 28.6 8 30c0 .3-.2.5-.5.5z"
    ></path>
    <path
      fill="currentColor"
      d="M8.5 23.5H6c-1.7 0-3-1.3-3-3s1.3-3 3-3h2.5c.3 0 .5.2.5.5v5c0 .3-.2.5-.5.5zm-2.5-5c-1.1 0-2 .9-2 2s.9 2 2 2h2v-4H6z"
    ></path>
    <path
      fill="currentColor"
      d="M6.5 28.5c-.3 0-.5-.2-.5-.5v-4.5c-.3 0-.7-.1-1-.2V28c0 .3-.2.5-.5.5S4 28.3 4 28v-5.5c0-.2.1-.4.3-.4s.4-.1.5 0c.4.3.8.4 1.2.4h.5c.3 0 .5.2.5.5v5c0 .3-.2.5-.5.5zm22 2c-.3 0-.5-.2-.5-.5 0-.8-.7-1.5-1.5-1.5S25 29.2 25 30c0 .3-.2.5-.5.5s-.5-.2-.5-.5c0-1.4 1.1-2.5 2.5-2.5S29 28.6 29 30c0 .3-.2.5-.5.5zm-2.5-7h-2.5c-.3 0-.5-.2-.5-.5v-5c0-.3.2-.5.5-.5H26c1.7 0 3 1.3 3 3s-1.3 3-3 3zm-2-1h2c1.1 0 2-.9 2-2s-.9-2-2-2h-2v4z"
    ></path>
    <path
      fill="currentColor"
      d="M27.5 28.5c-.3 0-.5-.2-.5-.5v-4.7c-.3.1-.7.2-1 .2V28c0 .3-.2.5-.5.5s-.5-.2-.5-.5v-5c0-.3.2-.5.5-.5h.5c.4 0 .8-.1 1.2-.4.2-.1.4-.1.5 0 .2.1.3.3.3.4V28c0 .3-.2.5-.5.5zm-7.5-4c-.3 0-.5-.2-.5-.5v-4c0-.3.2-.5.5-.5s.5.2.5.5v4c0 .3-.2.5-.5.5z"
    ></path>
    <path
      fill="currentColor"
      d="M21 20.5h-2c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h2c.3 0 .5.2.5.5s-.2.5-.5.5zm-9 2h-1c-.3 0-.5-.2-.5-.5v-2c0-.3.2-.5.5-.5h1c.8 0 1.5.7 1.5 1.5s-.7 1.5-1.5 1.5zm-.5-1h.5c.3 0 .5-.2.5-.5s-.2-.5-.5-.5h-.5v1z"
    ></path>
    <path
      fill="currentColor"
      d="M12 24.5h-1c-.3 0-.5-.2-.5-.5v-2c0-.3.2-.5.5-.5h1c.8 0 1.5.7 1.5 1.5s-.7 1.5-1.5 1.5zm-.5-1h.5c.3 0 .5-.2.5-.5s-.2-.5-.5-.5h-.5v1zm4.5 1c-.8 0-1.5-.7-1.5-1.5v-2c0-.8.7-1.5 1.5-1.5s1.5.7 1.5 1.5v2c0 .8-.7 1.5-1.5 1.5zm0-4c-.3 0-.5.2-.5.5v2c0 .3.2.5.5.5s.5-.2.5-.5v-2c0-.3-.2-.5-.5-.5z"
    ></path>
  </svg>
</template>
