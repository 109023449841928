import Service from "@/services/Service"
import ObjectUtils from "@/utilities/ObjectUtils"
import Device from "@/model/Device"
import StorageService from "./storage/StorageService"

export default class DevicesService extends Service {

  constructor(storageService: StorageService) {
    super(storageService)
  }

  async fetchDevice(locationName: string, onlyWithTable: boolean = false) {
    const devices = await this._fetch(this.data_url + "/devices" + (locationName ? `?locationName=${locationName}` : "") + (onlyWithTable ? '&onlyWithTableNumber=true' : '')) as Device[]
    ObjectUtils.fillReferences(devices)
    return devices
  }

  async fetchNewDevice() {
    const newDevice = (await this._fetch(
      this.data_url + "/devices/nextavailabledevice"
    )) as Device
    return newDevice
  }

  async fetchApiDevices() {
    let devices = await this._fetch(this.data_url + "/devices/api") as Device[]
    ObjectUtils.fillReferences(devices)
    return devices;
  }

  async updateDevice(device: Device) {
    return this._fetch(this.data_url + `/devices/${device.deviceIdentifier}`, {
      method: "PATCH",
      body: JSON.stringify(device)
    });
  }

  async assignTableToDevice(tableNumber: string, locationName: string, deviceIdentifier: string) {
    return this._fetch(
      this.data_url + `/devices/${deviceIdentifier}/assign`,
      {
        method: "POST",
        body: JSON.stringify({
          tableNumber,
          locationName
        })
      }
    )
  }

  async removeDevice(deviceIdentifier: string) {
    return this._fetch(this.data_url + `/devices/${deviceIdentifier}`, {
      method: "DELETE"
    }) as Promise<string>;
  }

  async createDevice() {
    return this._fetch(this.data_url + "/devices/api", {
      method: "POST"
    });
  }

  async createClientDevice() {
    return this._fetch(this.data_url + "/devices/client", {
      method: "POST"
    }) as Promise<Device>;
  }

  async unbindDevice(deviceIdentifier: string) {
    return this._fetch(this.data_url + `/devices/${deviceIdentifier}/unbind`, {
      method: "POST"
    });
  }
}
