<template>
  <VDialog v-model="dialog" max-width="400">
    <VCard class="transfer-dialog">
      <VCardTitle class="text-h5 pt-4 text-center">
        {{ $t("transfer-code") }}
      </VCardTitle>
      <VCardText class="text-center pa-6">
        <div v-if="transferCode" class="transfer-code-container">
          <div class="transfer-code">
            {{ formatTransferCode(transferCode) }}
          </div>
          <VBtn density="comfortable" variant="text" color="primary" prepend-icon="mdi-content-copy" @click="copyCode">
            {{ $t("copy") }}
          </VBtn>
        </div>
        <VProgressCircular v-else indeterminate color="primary" size="64" />
      </VCardText>
      <VCardActions class="pa-4">
        <VSpacer />
        <VBtn color="primary" variant="elevated" @click="closeDialog">
          {{ $t("close") }}
        </VBtn>
      </VCardActions>
    </VCard>
  </VDialog>
</template>

<script setup lang="ts">
import { services } from "@/plugins/installServices";
import eventBus, { EVENT_ERROR, EVENT_MESSAGE } from "@/services/EventBus";

const props = defineProps<{
  modelValue: boolean
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', value: boolean): void
}>();

const dialog = computed({
  get: () => props.modelValue,
  set: (value) => emit('update:modelValue', value)
});

const transferCode = ref('');
const $t = useI18n().t;

watch(() => props.modelValue, async (newVal) => {
  if (newVal) {
    try {
      transferCode.value = await services.authorizationService().fetchTransferCode();
    } catch (error) {
      eventBus.value.emit(EVENT_ERROR, $t("failed-to-fetch-transfer-code"));
      closeDialog();
    }
  }
});

const formatTransferCode = (code: string) => {
  return code.split('').join(' ');
};

const copyCode = () => {
  navigator.clipboard.writeText(transferCode.value);
  eventBus.value.emit(EVENT_MESSAGE, $t("code-copied"));
};

const closeDialog = () => {
  transferCode.value = '';
  dialog.value = false;
};
</script>

<style scoped>
.transfer-code-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.transfer-code {
  font-size: 2.5rem;
  font-weight: 600;
  letter-spacing: 0.5rem;
  padding: 1.5rem;
  background-color: rgba(var(--v-theme-primary), 0.1);
  border-radius: 8px;
  font-family: monospace;
}
</style>
