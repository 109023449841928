import { DirectiveBinding } from 'vue';

interface LongPressOptions {
	time: number;
}

export default {
	mounted(el: HTMLElement, binding: DirectiveBinding<(event: Event) => void>) {
		const options: LongPressOptions = {
			time: binding.arg ? parseInt(binding.arg) : 1000, // Optional time argument (defaults to 1000ms)
		};

		let pressTimer: ReturnType<typeof setTimeout> | null = null;

		// Function to start the timer
		const startPress = (event: Event) => {
			if (pressTimer === null) {
				pressTimer = setTimeout(() => {
					binding.value(event); // Call the provided method after threshold
				}, options.time);
			}
		};

		// Function to cancel the timer
		const cancelPress = () => {
			if (pressTimer !== null) {
				clearTimeout(pressTimer);
				pressTimer = null;
			}
		};

		// Add event listeners
		el.addEventListener('mousedown', startPress);
		el.addEventListener('touchstart', startPress); // For mobile devices

		// Cancel the timer on mouse/touch end or leaving
		el.addEventListener('mouseup', cancelPress);
		el.addEventListener('mouseleave', cancelPress);
		el.addEventListener('touchend', cancelPress);
		el.addEventListener('touchcancel', cancelPress);
	},
	unmounted(el: HTMLElement) {
		el.removeEventListener('mousedown', () => { });
		el.removeEventListener('mouseup', () => { });
		el.removeEventListener('mouseleave', () => { });
		el.removeEventListener('touchstart', () => { });
		el.removeEventListener('touchend', () => { });
		el.removeEventListener('touchcancel', () => { });
	},
};