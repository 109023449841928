import { ref, Ref } from 'vue'
import * as tus from 'tus-js-client'

export interface UploadProgress {
  bytesUploaded: number
  bytesTotal: number
  percentage: number
}

export interface UseTusUploadOptions {
  endpoint: string
  chunkSize?: number
  retryDelays?: number[]
  apiKey: string
  onSuccess?: (url: string) => void
  onError?: (error: Error) => void
}

export interface UseTusUploadReturn {
  upload: (file: File) => Promise<void>
  abort: () => void
  progress: Ref<UploadProgress>
  isUploading: Ref<boolean>
  error: Ref<Error | null>
  uploadUrl: Ref<string | null>
}

export async function useTusUpload(options: UseTusUploadOptions): Promise<UseTusUploadReturn> {
  const {
    endpoint,
    chunkSize = 5 * 1024 * 1024, // 5MB default
    retryDelays = [0, 3000, 5000, 10000, 20000],
    onSuccess,
    onError
  } = options

  const progress = ref<UploadProgress>({
    bytesUploaded: 0,
    bytesTotal: 0,
    percentage: 0
  })

  const isUploading = ref(false)
  const error = ref<Error | null>(null)
  const uploadUrl = ref<string | null>(null)
  let uploadInstance: tus.Upload | null = null

  const upload = async (file: File): Promise<void> => {
    let dimensions = await getImageDimensions(file);

    return new Promise((resolve, reject) => {
      if (!file) {
        const err = new Error('No file provided')
        error.value = err
        reject(err)
        return
      }

      error.value = null
      isUploading.value = true
      uploadUrl.value = null

      uploadInstance = new tus.Upload(file, {
        endpoint,
        retryDelays,
        chunkSize,
        metadata: {
          filename: file.name,
          filetype: file.type,
          filesize: file.size.toString()
        },
        headers: {
          "api_key": options.apiKey,
          "width": dimensions.width + '',
          "height": dimensions.height + ''
        },
        onError: (err: Error) => {
          error.value = err
          isUploading.value = false
          onError?.(err)
          reject(err)
        },
        onProgress: (bytesUploaded: number, bytesTotal: number) => {
          progress.value = {
            bytesUploaded,
            bytesTotal,
            percentage: (bytesUploaded / bytesTotal) * 100
          }
        },
        onSuccess: () => {
          if (uploadInstance?.url) {
            uploadUrl.value = uploadInstance.url
            isUploading.value = false
            onSuccess?.(uploadInstance.url)
            resolve()
          }
        }
      })

      uploadInstance.start()
    })
  }

  const abort = (): void => {
    if (uploadInstance) {
      uploadInstance.abort()
      isUploading.value = false
      uploadInstance = null
    }
  }

  // Function to get image dimensions
  const getImageDimensions = (file: File): Promise<{ width: number; height: number }> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (e) => {
        const img = new Image();

        img.onload = () => {
          resolve({ width: img.width, height: img.height });
        };

        img.onerror = () => {
          reject(new Error('Failed to load image'));
        };

        img.src = e.target?.result as string;
      };
      reader.readAsDataURL(file);
    });
  }

  return {
    upload,
    abort,
    progress,
    isUploading,
    error,
    uploadUrl
  }
}
