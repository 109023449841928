import { ref } from 'vue';

// Define and export event types
export const EVENT_MESSAGE = 'event:message';
export const EVENT_WARNING = 'event:warning';
export const EVENT_ERROR = 'event:error';
export const EVENT_SESSION_INVALIDATED = 'event:session_invalidated';
/**
 * EventBus class
 * This class is used to manage events in the application.
 */
class EventBus {
  // An object to hold arrays of callback functions for each event
  private listeners: Record<string, Function[]> = {};

  /**
   * Emit an event.
   * This method is used to trigger an event and call all listeners for that event.
   * @param {string} event - The name of the event to emit.
   * @param {...any[]} args - Any number of arguments to pass to the listeners.
   */
  emit(event: string, ...args: any[]): void {
    if (this.listeners[event]) {
      this.listeners[event].forEach((listener) => {
        try {
          listener(...args);
        } catch (error) {
          console.error(`Error occurred in listener of event "${event}":`, error);
        }
      });
    }
  }

  /**
   * Add a listener for an event.
   * This method is used to register a callback function to be called when an event is emitted.
   * @param {string} event - The name of the event to listen for.
   * @param {Function} callback - The function to call when the event is emitted.
   */
  on(event: string, callback: Function): void {
    if (!this.listeners[event]) {
      this.listeners[event] = [];
    }
    this.listeners[event].push(callback);
  }

  /**
   * Remove a listener for an event.
   * This method is used to remove a previously registered callback function from an event.
   * @param {string} event - The name of the event to remove the listener from.
   * @param {Function} callback - The function to remove.
   */
  off(event: string, callback: Function): void {
    if (this.listeners[event]) {
      this.listeners[event] = this.listeners[event].filter(listener => listener !== callback);
    }
  }
}

// Create a reactive reference to an instance of EventBus
const eventBus = ref(new EventBus())

// Export the EventBus instance as the default export
export default eventBus;
