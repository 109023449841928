import { getDefaultState } from "@/services/storage/StoreData"
import { StoreData } from "@/services/storage/StoreData"
import eventBus, { EVENT_ERROR, EVENT_SESSION_INVALIDATED } from "./EventBus";
import StorageService from "./storage/StorageService";


export default class Service {

  private data: StoreData | undefined

  protected data_url = ""
  protected storageService: StorageService;


  constructor(storageService: StorageService) {
    this.storageService = storageService
    this.data_url = "/api";
  }

  protected storeData = (): StoreData => {
    if (this.data) {
      return this.data
    }
    return this.storageService.data() || getDefaultState()
  }


  public injectData(data: StoreData) {
    this.data = data
  }

  _fetch = async (...args: any[]) => {
    if (args.length < 2) {
      args.push({})
    }
    let secondArg = args[1];
    if (!secondArg.headers) {
      secondArg.headers = new Headers();
    }
    secondArg.headers.append("api_key", this.storeData().session?.apiKey);
    secondArg.headers.append("correlationId", this.storageService.deviceName() + "/" + Math.floor(Date.now()));
    secondArg.headers.append("deviceName", this.storageService.deviceName())
    if (secondArg.method === "DELETE" && (secondArg.body === null || secondArg.body === undefined || secondArg.body === "{}")) {
      secondArg.body = [];
    }

    secondArg.signal = AbortSignal.timeout(30000)
    try {
      return await $fetch(args[0], { ...args[1] });
    } catch (error: any) {
      if (error.statusCode === 401) {
        eventBus.value.emit(EVENT_SESSION_INVALIDATED, "Session expired.");
      }
      if (error.statusCode === 500) {
        eventBus.value.emit(EVENT_ERROR, "Server error. Please try again later.");
      }
      throw error;
    }
  };
}
