<template>
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
		<!-- Table base -->
		<path d="M2 17h20v1H2z" fill="currentColor" />
		<path d="M4 18l-0.5 3h17L20 18" fill="currentColor" />

		<!-- Detailed QR code with finder patterns -->
		<!-- Top-left finder pattern -->
		<path d="M4 2h7v7H4V2zM5 3v5h5V3H5z" fill="currentColor" />
		<rect x="6" y="4" width="3" height="3" fill="currentColor" />

		<!-- Top-right finder pattern -->
		<path d="M13 2h7v7h-7V2zM14 3v5h5V3h-5z" fill="currentColor" />
		<rect x="15" y="4" width="3" height="3" fill="currentColor" />

		<!-- Bottom-left finder pattern -->
		<path d="M4 9h7v7H4V9zM5 10v5h5v-5H5z" fill="currentColor" />
		<rect x="6" y="11" width="3" height="3" fill="currentColor" />

		<!-- Dense QR code data pattern -->
		<path d="
    M13 9h1v1h-1z M14 9h1v1h-1z M15 9h1v1h-1z M16 9h1v1h-1z M17 9h1v1h-1z M18 9h1v1h-1z M19 9h1v1h-1z
    M13 10h1v1h-1z M15 10h1v1h-1z M17 10h1v1h-1z M19 10h1v1h-1z
    M13 11h1v1h-1z M14 11h1v1h-1z M15 11h1v1h-1z M16 11h1v1h-1z M18 11h1v1h-1z
    M13 12h1v1h-1z M15 12h1v1h-1z M17 12h1v1h-1z M19 12h1v1h-1z
    M13 13h1v1h-1z M14 13h1v1h-1z M16 13h1v1h-1z M18 13h1v1h-1z
    M13 14h1v1h-1z M15 14h1v1h-1z M17 14h1v1h-1z
    M13 15h1v1h-1z M14 15h1v1h-1z M15 15h1v1h-1z M16 15h1v1h-1z M18 15h1v1h-1z M19 15h1v1h-1z
  " fill="currentColor" />

		<!-- Timing patterns -->
		<path d="
    M11 3h1v1h-1z M11 5h1v1h-1z M11 7h1v1h-1z M11 9h1v1h-1z M11 11h1v1h-1z M11 13h1v1h-1z M11 15h1v1h-1z
    M6 8h1v1H6z M8 8h1v1H8z M10 8h1v1h-1z M12 8h1v1h-1z M14 8h1v1h-1z M16 8h1v1h-1z M18 8h1v1h-1z
  " fill="currentColor" />

		<!-- Alignment pattern -->
		<path d="M16 11h2v2h-2z" fill="currentColor" />

		<!-- Format information -->
		<path d="
    M4 8h1v1H4z
    M8 4h1v1H8z
    M8 6h1v1H8z
    M19 4h1v1h-1z
    M19 6h1v1h-1z
  " fill="currentColor" />
	</svg>
</template>
