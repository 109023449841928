
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexgMGFi8WlG77S618WdZ1jmt6i_m9yVq9R7Lrn15_YwVEMeta } from "/builds/pichenlin/ordernow/pages/index.vue?macro=true";
import { default as indexTAjGll5ZqYJGmi_tCcMa5tGS5V5RXSg0_fg8UQbxaaYMeta } from "/builds/pichenlin/ordernow/pages/client/index.vue?macro=true";
import { default as indexjPPVwvVzsDgiSMS5r3dR5hYfAnQrZjdOEFN8XP6bp6gMeta } from "/builds/pichenlin/ordernow/pages/tables/index.vue?macro=true";
import { default as indexnp6Tufd88suBpVHCUkNZsQ8UmFqNtWKoS8p815wyN7MMeta } from "/builds/pichenlin/ordernow/pages/reviews/index.vue?macro=true";
import { default as indexnCLa_45JSkP6rqD27Q_j8G26t5l2J4iU0dH_VHFYw0bsIMeta } from "/builds/pichenlin/ordernow/pages/dashboard/index.vue?macro=true";
import { default as indexdWo_14TzndTrVh1hEdQWJfXcnu961BS6dP0uwbY68ToMeta } from "/builds/pichenlin/ordernow/pages/admin/users/index.vue?macro=true";
import { default as indexpQAWAuvhvEyQ5SMtH9JBam6LzB_DVrKbgJoTUDg3MDsMeta } from "/builds/pichenlin/ordernow/pages/admin/agents/index.vue?macro=true";
import { default as indexnWQ2RGPRrtP2nN3JVdAQyR1tXTx1x4U8XUlaUgEDTT0Meta } from "/builds/pichenlin/ordernow/pages/admin/apikeys/index.vue?macro=true";
import { default as indexFWQ631vX7aWDER4D25mZ_45R8WYw_pklSNksu_ptblhykMeta } from "/builds/pichenlin/ordernow/pages/admin/licenses/index.vue?macro=true";
import { default as indexZdG0jPRbPnahj0dFspC4mNvadGDdY2glNED3N1z4SDsMeta } from "/builds/pichenlin/ordernow/pages/client/welcome/index.vue?macro=true";
import { default as indexZdXI69_TyrH_45YoCr_LdJRqKjiKFwVeP6y67OgbnMKXEMeta } from "/builds/pichenlin/ordernow/pages/admin/resources/index.vue?macro=true";
import { default as index7drQyxrr7k8xvQmhFtcDtA34ukXDuor1Laf3Atn2ld0Meta } from "/builds/pichenlin/ordernow/pages/admin/menueditor/index.vue?macro=true";
import { default as indexa1a_kU0ApPDXSapXnQGv28WnEVVUn_OTGlZBFnZiu_45sMeta } from "/builds/pichenlin/ordernow/pages/admin/configurations/index.vue?macro=true";
import { default as indexzzvAzgsi_45Ov8mPTxE2BGsNA0Jot54ygC2RCBr0nksxgMeta } from "/builds/pichenlin/ordernow/pages/admin/itemlisteditor/index.vue?macro=true";
import { default as indexjgwHWm7bF6Ey4XaUeRyFXQYqjTiOcA3gLQWvMMVgNAUMeta } from "/builds/pichenlin/ordernow/pages/admin/ordersoverview/index.vue?macro=true";
import { default as _91sessionId_939VZTzfqRFpeyVbhfIEIvg6uiaWdYD9gtPCYDTc7vvEAMeta } from "/builds/pichenlin/ordernow/pages/client/session/[sessionId].vue?macro=true";
import { default as indexqIsD4RFNOVQG7aAhJKedULBnJI4xmppbw9FLjeH5tn4Meta } from "/builds/pichenlin/ordernow/pages/admin/configurations/content/index.vue?macro=true";
import { default as _91license_93_45_91location_93_45_91tablenumber_9363xcMhGFK2XFtpPm1KUf9e5OCtSZD7ItySCeeFyKs8gMeta } from "/builds/pichenlin/ordernow/pages/client/newsession/[license]-[location]-[tablenumber].vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/builds/pichenlin/ordernow/pages/index.vue")
  },
  {
    name: "client",
    path: "/client",
    component: () => import("/builds/pichenlin/ordernow/pages/client/index.vue")
  },
  {
    name: "tables",
    path: "/tables",
    component: () => import("/builds/pichenlin/ordernow/pages/tables/index.vue")
  },
  {
    name: "reviews",
    path: "/reviews",
    component: () => import("/builds/pichenlin/ordernow/pages/reviews/index.vue")
  },
  {
    name: "dashboard",
    path: "/dashboard",
    component: () => import("/builds/pichenlin/ordernow/pages/dashboard/index.vue")
  },
  {
    name: "admin-users",
    path: "/admin/users",
    component: () => import("/builds/pichenlin/ordernow/pages/admin/users/index.vue")
  },
  {
    name: "admin-agents",
    path: "/admin/agents",
    component: () => import("/builds/pichenlin/ordernow/pages/admin/agents/index.vue")
  },
  {
    name: "admin-apikeys",
    path: "/admin/apikeys",
    component: () => import("/builds/pichenlin/ordernow/pages/admin/apikeys/index.vue")
  },
  {
    name: "admin-licenses",
    path: "/admin/licenses",
    component: () => import("/builds/pichenlin/ordernow/pages/admin/licenses/index.vue")
  },
  {
    name: "client-welcome",
    path: "/client/welcome",
    component: () => import("/builds/pichenlin/ordernow/pages/client/welcome/index.vue")
  },
  {
    name: "admin-resources",
    path: "/admin/resources",
    component: () => import("/builds/pichenlin/ordernow/pages/admin/resources/index.vue")
  },
  {
    name: "admin-menueditor",
    path: "/admin/menueditor",
    component: () => import("/builds/pichenlin/ordernow/pages/admin/menueditor/index.vue")
  },
  {
    name: "admin-configurations",
    path: "/admin/configurations",
    component: () => import("/builds/pichenlin/ordernow/pages/admin/configurations/index.vue")
  },
  {
    name: "admin-itemlisteditor",
    path: "/admin/itemlisteditor",
    component: () => import("/builds/pichenlin/ordernow/pages/admin/itemlisteditor/index.vue")
  },
  {
    name: "admin-ordersoverview",
    path: "/admin/ordersoverview",
    component: () => import("/builds/pichenlin/ordernow/pages/admin/ordersoverview/index.vue")
  },
  {
    name: "client-session-sessionId",
    path: "/client/session/:sessionId()",
    component: () => import("/builds/pichenlin/ordernow/pages/client/session/[sessionId].vue")
  },
  {
    name: "admin-configurations-content",
    path: "/admin/configurations/content",
    component: () => import("/builds/pichenlin/ordernow/pages/admin/configurations/content/index.vue")
  },
  {
    name: "client-newsession-license-location-tablenumber",
    path: "/client/newsession/:license()-:location()-:tablenumber()",
    component: () => import("/builds/pichenlin/ordernow/pages/client/newsession/[license]-[location]-[tablenumber].vue")
  }
]