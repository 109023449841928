import Service from "@/services/Service"
import StorageService from "@/services/storage/StorageService"

export enum Transaction {
	READ_WRITE,
	READONLY
}

const withStoreAsync = <T>(service: Service, fn: (...args: any[]) => Promise<T>, transactional: Transaction = Transaction.READONLY) => {
	return async function (...args: any[]): Promise<T> {
		let result: T = null as any
		let { store } = useNuxtApp()
		let storageService = store! as StorageService
		let failed = false
		try {
			if (import.meta.client) {
				service.injectData(
					storageService.data(transactional == Transaction.READ_WRITE)
				)
			} else {
				throw new Error(
					"This function is only availabe client side. It needs the data store."
				)
			}
			result = await fn.apply(service, args);
		} catch (error) {
			console.error("Error in withStore: ", error)
			failed = true
			throw error
		} finally {
			if (!failed && transactional == Transaction.READ_WRITE && import.meta.client) {
				storageService.commit()
			}
		}
		return result
	}
}

const withStore = <T>(service: Service, fn: (...args: any[]) => T, transactional: Transaction = Transaction.READONLY) => {
	return function (...args: any[]): T {
		let result: T = null as any
		let { store } = useNuxtApp()
		let storageService = store! as StorageService
		let failed = false
		try {
			if (import.meta.client) {
				service.injectData(
					storageService.data(transactional == Transaction.READ_WRITE)
				)
			} else {
				throw new Error(
					"This function is only availabe client side. It needs the data store."
				)
			}
			result = fn.apply(service, args);
		} catch (error) {
			failed = true
			console.error("Error in withStore: " + error)
		} finally {
			if (!failed && transactional == Transaction.READ_WRITE && import.meta.client) {
				storageService.commit()
			}
		}
		return result
	}
}

export { withStoreAsync, withStore }