import ReferenceData, { createDefaultReferenceData } from '@/model/referencedata/ReferenceData';
import Service from "./Service"
import { Item } from "@/model/referencedata/Item";
import StorageService from "./storage/StorageService";
import CustomerType from "~/model/referencedata/CustomerType";
import Page from "~/model/referencedata/Page";
import { createDownloadableFile } from '@/utilities/FileList';


const referenceDataCache: Map<string, ReferenceData> = new Map();
export default class ReferenceDataService extends Service {

  constructor(storageService: StorageService) {
    super(storageService)
  }



  setReferenceData(name: string, referenceData: ReferenceData) {
    referenceDataCache.set(name, referenceData);
  }

  /**
   * Asynchronously publishes a file to the specified reference data.
   *
   * @param {string} referenceDataName - The name of the reference data (default value: "default").
   * @param {File} uploadFile - The file to be uploaded.
   * @return {Promise} A Promise that resolves with the response from the server.
   */
  async publishFile(referenceDataName: string = "default", uploadFile: File) {
    const formData = new FormData();
    formData.append("file", uploadFile);
    let response = await this._fetch(`${this.data_url}/configuration/referenceData/${referenceDataName}`, {
      method: "POST",
      body: formData
    });
    let referenceData = response.value as ReferenceData;
    referenceDataCache.set(referenceDataName, this.fixItemReferences(referenceData));
    return response;
  }

  /**
   * Asynchronously publishes a configuration to the specified reference data.
   *
   * @param {string} referenceDataName - The name of the reference data (default value: "default").
   * @return {Promise} A Promise that resolves with the response from the server.
   */
  async publishConfiguration(referenceDataName: string = "default", updater: (referenceData: ReferenceData) => void = () => true) {
    let referenceData: ReferenceData | undefined = referenceDataCache.get(referenceDataName);
    let isNew = referenceData == undefined
    if (!referenceData) referenceData = createDefaultReferenceData()
    updater(referenceData)
    return this._fetch(`${this.data_url}/configuration/referenceData/${referenceDataName}`, {
      method: isNew ? "POST" : "PUT",
      body: referenceData
    });
  }

  async export(configurationname: string) {
    let referenceData: ReferenceData | undefined = referenceDataCache.get(configurationname)
    if (referenceData) {
      createDownloadableFile(JSON.stringify(referenceData), configurationname + "@" + new Date().toISOString() + ".json", "application/json")
    } else {
      throw Error("Unkown configurationname passed to export method. " + configurationname)
    }
  }

  async publishCustomerTypes(referenceDataName: string = "default", customerTypes: Array<CustomerType>) {
    return this._fetch(`${this.data_url}/configuration/referenceData/${referenceDataName}/customerTypes`, {
      method: "PUT",
      body: customerTypes
    });
  }

  async publishPages(referenceDataName: string = "default", pages: Array<Page>) {
    return this._fetch(`${this.data_url}/configuration/referenceData/${referenceDataName}/pages`, {
      method: "PUT",
      body: pages
    });
  }

  async copy(sourceReferenceDataName: string, newReferenceDataName: string) {
    return this._fetch(`${this.data_url}/configuration/referenceData/${sourceReferenceDataName}/copy`, {
      method: "POST",
      body: newReferenceDataName
    });
  }

  async updateItem(referenceDataName: string = "default", item: Item) {
    return await this._fetch(`${this.data_url}/configuration/referenceData/${referenceDataName}/items/${item.number}`, {
      method: "PUT",
      body: item
    });
  }

  async allConfigurationNames() {
    return this._fetch(`${this.data_url}/configuration/referenceData/names`) as Promise<string[]>;
  }

  async getConfiguration(name: string = "default", usingCache: boolean = false): Promise<ReferenceData> {
    if (usingCache && referenceDataCache.has(name)) {
      return this.fixItemReferences(referenceDataCache.get(name)! as ReferenceData);
    }
    let config = (await this._fetch(`${this.data_url}/configuration/referenceData/${name}`) as any).value
    referenceDataCache.set(name, this.fixItemReferences(config));
    return this.fixItemReferences(config);
  }

  async addOrUpdateItems(name: string = "default", configFile: File) {
    const formData = new FormData();
    formData.append("file", configFile);
    let response = await this._fetch(`${this.data_url}/configuration/referenceData/${name}/file`, {
      method: "POST",
      body: formData
    });
    this.setReferenceData(name, response.value as ReferenceData)
    return response;
  }

  async deleteConfiguration(name: string = "default") {
    await this._fetch(`${this.data_url}/configuration/referenceData/${name}`, {
      method: "DELETE"
    });
    referenceDataCache.delete(name);
  }

  private fixItemReferences(referenceData: ReferenceData) {
    if (referenceData.pages !== undefined && referenceData.items !== undefined) {
      referenceData.pages.forEach(page => {
        let itemsOnpage: Item[] = [];
        page.items?.forEach((item: { number: number; }) => {
          let itemFound = referenceData.items.find(i => i.number === item.number)
          if (itemFound) itemsOnpage.push(itemFound);
        });
        page.items = itemsOnpage;
      });
    }
    return referenceData
  }

}
