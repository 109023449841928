import Service from "@/services/Service"
import StorageService from "./storage/StorageService"

export default class ClientService extends Service {
  constructor(storageService: StorageService) {
    super(storageService)
  }

  async fetchConfiguration() {
    return await this._fetch(this.data_url + "/devices")
  }

}
