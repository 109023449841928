import Review from "@/model/Review";
import eventBus, { EVENT_ERROR } from "./EventBus";
import Service from "./Service"
import Location from "@/model/Location"
import StorageService from "./storage/StorageService";
import Command from "@/model/Command";

export default class LocationService extends Service {

    constructor(storageService: StorageService) {
        super(storageService)
    }

    async fetchLocationsFor(licenseCode: string) {
        let result = await this._fetch(`${this.data_url}/locations?licenseCode=${licenseCode}`);
        return result as Location[];
    }

    async fetchLocationsWithName(locationName: string) {
        let result = await this._fetch(`${this.data_url}/locations?locationName=${locationName}`) as Location[];
        if (result.length == 0) {
            return null;
        }
        return result[0] as Location;
    }

    async fetchLocations(): Promise<Location[]> {
        return (await this._fetch(
            this.data_url + "/locations")) as Location[]
    }

    async fetchReviewsForLocation(location: Location, date: string) {
        return await this._fetch(this.data_url + `/reviews?locationName=${location.name}&date=${date.substring(0, 10)}`) as Review[]
    }

    async storeLocation(licenseCode: string, location: Location) {
        return await this._fetch(`${this.data_url}/locations?licenseCode=${licenseCode}`, {
            method: "POST",
            body: JSON.stringify(location)
        })
    }

    async deleteLocation(licenseCode: string, location: Location) {
        try {
            await this._fetch(`${this.data_url}/locations/${btoa(location.name!!)}?licenseCode=${licenseCode}`, {
                method: "DELETE"
            })
            return true;
        } catch (error) {
            eventBus.value.emit(EVENT_ERROR, "Error deleting location: " + error)
            console.error("error", "Error deleting location: " + error)
            return false;
        }
    }

    async generateReviewsDocument(body: any) {
        return await this._fetch(`${this.data_url}/generatePDF/reviewReport`, {
            method: "POST",
            body: JSON.stringify(body)
        })
    }

    async fetchMainData(locationName: string) {

        const command: Command = {
            commandType: 'FETCH_REMOTE_MENU',
            locationName
        };

        try {
            const response = await this._fetch(this.data_url + "/commands", {
                method: "POST",
                body: JSON.stringify(command)
            });
            return response as Command;
        } catch (error) {
            console.error("Error sending command:", error);
            throw error;
        }
    }
}