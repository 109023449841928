import { UploadableFile } from "@/utilities/FileList";
import Service from "./Service";
import Resource from "@/model/Resource";
import StorageService from "./storage/StorageService";

export default class ResourcesService extends Service {


	constructor(storageService: StorageService) {
		super(storageService)
	}

	async upload(uploadFile: UploadableFile) {
		const formData = new FormData();
		let dimensions = await this.getImageDimensions(uploadFile.file);
		if (dimensions && dimensions.width > 0 && dimensions.height > 0) {
			formData.append("width", dimensions.width.toString());
			formData.append("height", dimensions.height.toString());
		}
		formData.append("file", uploadFile.file);
		formData.append('name', uploadFile.file.name)
		formData.append('size', uploadFile.file.size.toString())
		formData.append('lastModified', uploadFile.file.lastModified.toString())
		formData.append('type', uploadFile.file.type)

		return this._fetch(`${this.data_url}/resources/upload`, {
			method: "POST",
			body: formData
		});
	}

	async deleteResource(resource: Resource) {
		return this._fetch(`${this.data_url}/resources/${resource.name}`, {
			method: "DELETE",
		});
	}

	async getResources() {
		return this._fetch(`${this.data_url}/resources`) as Promise<Resource[]>;
	}

	isImage(file: File): boolean {
		return file.type.startsWith('image/');
	}

	// Function to get image dimensions
	getImageDimensions(file: File): Promise<{ width: number; height: number }> {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();

			reader.onload = (e) => {
				const img = new Image();

				img.onload = () => {
					resolve({ width: img.width, height: img.height });
				};

				img.onerror = () => {
					reject(new Error('Failed to load image'));
				};

				img.src = e.target?.result as string;
			};
			reader.readAsDataURL(file);
		});
	}

}