import ObjectUtils from "@/utilities/ObjectUtils"
import { extractDeviceName } from "@/utilities/StringUtils"
import {
  Client,
  Message,
  StompSubscription
} from "@stomp/stompjs"
import { StompConfig } from "@stomp/stompjs/esm6"
import ConnectionListener from "./ConnectionListener"
import Event from "@/model/events/Event"
import { services } from "../plugins/installServices";

export class SocketService {

  private globalEventListeners: Array<(message: Message) => void> = []

  private connectionListener: ConnectionListener | undefined

  private SERVER_URL?: string

  private client?: Client

  private eventListeners: Map<string, ((body: any) => void)[]> = new Map()

  private deviceIdentifier?: string

  private subscriptions: any[] = []

  private commands: (() => void)[] = []

  public registerGlobalEventListener(listener: (message: Message) => void) {
    if (this.globalEventListeners.indexOf(listener) === -1) {
      this.globalEventListeners.push(listener)
    }
  }

  public addEventListener(
    name: string,
    eventlistener: (body: any) => any
  ): SocketService {
    let listeners = this.eventListeners.get(name)
    if (!listeners) {
      listeners = []
      this.eventListeners.set(name, listeners)
    }
    listeners?.push(eventlistener)
    return this
  }

  public async sendMessage(event: Event) {
    if (!this.client) {
      throw new Error('Socket client not initialized');
    }
    const apiKey = services.storageService().data().session?.apiKey;
    const deviceName = services.storageService().deviceName();
    event.uuid = deviceName + "/" + Math.floor(Date.now());
    if (!deviceName) {
      throw new Error('Device name not set');
    }

    const command = () => {
      try {
        this.client?.publish({
          destination: "/app/" + event.eventType,
          body: JSON.stringify(event),
          headers: {
            "correlationId": event.uuid,
            "api_key": apiKey || "",
            deviceName
          }
        });
      } catch (error) {
        log("error", `Failed to send message: ${error}`);
        throw error;
      }
    };

    if (!this.client.connected) {
      if (this.commands.length >= 100) { // Prevent memory leaks
        this.commands.shift();
      }
      this.commands.push(command);
    } else {
      command();
    }
  }

  public async init() {
    this.connect()
    if (this.client) {
      this.client.onConnect = () => {
        if (this.client && this.deviceIdentifier) {
          this.subscribe(this.deviceIdentifier)
        }
        if (this.commands && this.commands.length > 0) {
          this.commands.forEach((command) => {
            command()
          })
          this.commands = []
        }
        this.connectionListener?.status(true, "Connected")
      }
      this.client.activate()
    }
    return this
  }


  public async subscribe(identifier: string) {
    if (this.subscriptions) {
      this.subscriptions.forEach(
        (subscription: StompSubscription | undefined) => {
          if (subscription) {
            this.client?.unsubscribe(subscription.id)
          }
        }
      )
      this.subscriptions = [];
    }
    this.subscriptions.push(
      this.client?.subscribe(
        "/topic/events_" + identifier,
        (message: Message) => {
          const deviceName = services.storageService().deviceName()
          const payload = JSON.parse(message.body)
          let uuid = payload["uuid"] as string
          let sourceDeviceName = extractDeviceName(uuid)
          if (!sourceDeviceName || sourceDeviceName !== deviceName) {
            ObjectUtils.fillReferences(payload)
            const eventListeners = this.eventListeners.get(payload.eventType)
            if (eventListeners) {
              eventListeners.forEach(eventListener => {
                eventListener(payload)
              })
            } else {
              // Fallback if no listener registered for this event type for the given identifier
              this.globalEventListeners.forEach(
                (eventlistener: (body: any) => any) => {
                  eventlistener(payload)
                }
              )
            }
          }
        },
        {
          apiKey: this.deviceIdentifier!!,
          deviceName: services.storageService().deviceName()
        }
      )
    )
    let clientSession = services.storageService().data().session?.clientSession
    if (!clientSession) {
      this.subscriptions.push(
        this.client?.subscribe(
          "/topic/events",
          (message: Message) => {
            const deviceName = services.storageService().deviceName()
            const payload = JSON.parse(message.body)
            let uuid = payload["uuid"]
            let sourceDeviceName = extractDeviceName(uuid)
            if (!sourceDeviceName || sourceDeviceName !== deviceName) {
              ObjectUtils.fillReferences(payload)
              const eventListeners = this.eventListeners.get(payload.eventType)
              if (eventListeners) {
                eventListeners.forEach(eventListener => {
                  eventListener(payload)
                })
              } else {
                // Fallback if no listener registered for this event type for the given identifier
                this.globalEventListeners.forEach(
                  (eventlistener: (body: any) => any) => {
                    eventlistener(payload)
                  }
                )
              }
            }
          },
          {
            apiKey: this.deviceIdentifier!!,
            deviceName: services.storageService().deviceName()
          }
        )
      )
    }
  }

  public configure(
    deviceIdentifier?: string,
    connectionListener?: ConnectionListener
  ): SocketService {
    this.connectionListener = connectionListener
    this.deactive()
    this.SERVER_URL = `${process.env.VUE_APP_WS_URL}/gs-guide-websocket/websocket`
    if (this.SERVER_URL?.startsWith("https")) {
      this.SERVER_URL = this.SERVER_URL.replace("https", "wss")
    }
    if (this.SERVER_URL?.startsWith("http")) {
      this.SERVER_URL = this.SERVER_URL.replace("http", "ws")
    }
    this.deviceIdentifier = deviceIdentifier
    return this
  }

  private connect() {
    (this.client = new Client({
      brokerURL: this.SERVER_URL,
      reconnectDelay: 3000,
      heartbeatIncoming: 2000,
      heartbeatOutgoing: 2000,
      onWebSocketClose: () => {
        this.connectionListener?.status(false, "Disconnected")
      }
    } as StompConfig)),
    {
      api_key: this.deviceIdentifier!!
    }
  }

  private deactive() {
    if (this.client) {
      this.client.deactivate()
      this.client = undefined
    }
  }
}

const socketService = new SocketService()

export default socketService
