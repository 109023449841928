import { createVuetify, IconProps, IconSet } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import { mdi } from "vuetify/iconsets/mdi";
import { en, fr, de, zhHans } from 'vuetify/locale'
import Robot from "~/icons/robot.vue";
import QRCodeTable from "~/icons/qrcode-table.vue";
import VueApexCharts from "vue3-apexcharts";
import ApexCharts from "apexcharts";


export default defineNuxtPlugin((nuxtApp) => {

  const customSvgNameToComponent: any = {
    Robot,
    QRCodeTable
  };

  const custom: IconSet = {
    component: (props: IconProps) =>
      h(props.tag, [h(customSvgNameToComponent[props.icon as string], { class: 'v-icon__svg' })]),
  }

  const vuetify = createVuetify({
    components,
    directives,
    locale: {
      locale: 'en',
      fallback: 'en',
      messages: { en, fr, de, zhHans }
    },
    icons: {
      defaultSet: "mdi",
      sets: {
        mdi,
        custom
      }
    },
    defaults: {
      global: {

      }
    },
    theme: {
      defaultTheme: "dark",
      themes: {
        light: {
          colors: {
            primary: '#FF6B6B', // Your custom primary color
            secondary: '#333333', // Your custom secondary color
            accent: '#2ECC71', // Your custom accent color
          }
        },
        dark: {
          colors: {
            primary: '#FF6B6B', // Your custom primary color
            secondary: '#333333', // Your custom secondary color
            accent: '#2ECC71', // Your custom accent color
          }
        },
      }
    }
  });

  nuxtApp.vueApp.use(vuetify);
  nuxtApp.vueApp.use(VueApexCharts);
  nuxtApp.vueApp.config.globalProperties.$apexcharts = ApexCharts;
  return {
    provide: {
      vuetify: vuetify
    }
  }
});

// Add this when into a TypeScript codebase
declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $apexcharts: typeof ApexCharts;
  }
}
