import type ReferenceData from "@/model/referencedata/ReferenceData"
import { format } from "date-fns-tz"

export const formatCurrency = (value: number, referenceData: ReferenceData) => {
  if (!value || value === 0) {
    return ""
  }
  let currency = referenceData?.currency || "EUR"
  let format = referenceData?.currencyFormat || "de-DE"
  return new Intl.NumberFormat(format, {
    style: "currency",
    currency
  }).format(value)
}

/**
 * Formats the given UTC date into a specified format based on the user's local time zone.
 *
 * @param {string | Date} date - The UTC date time to be formatted.
 * @param {string} formatStr - The format string for the date time. Defaults to 'yyyy-MM-dd HH:mm:ss'.
 * @return {string} The formatted date time string in the user's local time.
 */
export const formatDateTime = (date: string | Date, formatStr = 'yyyy-MM-dd HH:mm:ss'): string => {
  const utcDate = typeof date === 'string' ? new Date(date) : date;
  const localDate = new Date(utcDate.getTime() - utcDate.getTimezoneOffset() * 60000);
  return format(localDate, formatStr);
}


/**
 * Extract deviceName from UUID 
 */
export const extractDeviceName = (uuid: string): string => {
  if (!uuid || !uuid.includes('/')) return "";
  // Count the number of '/' characters
  const slashCount = (uuid.match(/\//g) || []).length;

  // Check if there are exactly 3 '/' characters
  if (slashCount === 3) {
    // Find the index of the last '/'
    const lastSlashIndex = uuid.lastIndexOf('/');

    // Return the substring before the last '/'
    return uuid.substring(0, lastSlashIndex);
  }

  // Return empty string if conditions aren't met
  return '';
}

