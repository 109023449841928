import Tracker from "@openreplay/tracker"
let instance = null;
if (process.env.NODE_ENV === "development") {
  console.log("OpenReplay is disabled in development mode.")
  instance = undefined
} else {
  instance = new Tracker({
    projectKey: "bwl1aBl8aFMYaGiLA6g3",
  });
}
const tracker = instance as Tracker;
export default tracker;

export const setUserId = (userId: string) => {
  if (tracker !== undefined) {
    if (userId !== undefined || userId !== null || userId !== "") {
      tracker.setUserAnonymousID(userId);
    } else {
      tracker.setUserID(userId);
    }
  }
}
