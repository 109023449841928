import DeviceConfiguration from "./DeviceConfiguration"
import MenuSelection from "./MenuSelection"
import Order from "./Order"
import User from "./User"
import ItemType from "./referencedata/ItemType"

export default class Session {
  public name?: string
  public user?: User
  public clientSession: boolean = false
  public licenseCode?: string
  public apiKey?: string
  public deviceConfiguration?: DeviceConfiguration
  public menuSelection: MenuSelection[] = []
  public orders: Order[] = []
  public locale: string = "en"
  public assigned: boolean = true
  public deviceNames: string[] = []
  public displayMenuSelection() {
    return this.menuSelection
      .map((selection) => {
        return selection.quantity + " x " + selection.customerType?.name
      })
      .join(", ")
  }

  public bindingUrl() {
    let customUrl = process.env.VUE_APP_CUSTOM_URL
    if (customUrl) {
      return customUrl + "/client/session/" + this.apiKey
    } else {
      return window.location.origin + "/client/session/" + this.apiKey
    }
  }

  public maxNumberOfItems() {
    let max = 0;
    if (this.menuSelection && this.menuSelection.length > 0) {
      var totalNumberOfGuests = 0;
      this.menuSelection.forEach((selection) => {
        totalNumberOfGuests += selection.quantity
      });
      let maxNumberOfItems = 0;
      this.menuSelection.forEach((selection) => {
        if (selection.customerType.maxNumberOfItems > maxNumberOfItems) {
          maxNumberOfItems = selection.customerType.maxNumberOfItems;
        }
      });
      max = maxNumberOfItems * totalNumberOfGuests;
    }
    return max > 0 ? max : 999;
  }

  public coolDownSeconds(currentTime: Date) {
    // Check if orders and menuSelection are arrays and have elements, and if customerType and minutesBetweenOrders are defined
    if (!Array.isArray(this.orders) || this.orders.length === 0 ||
      !Array.isArray(this.menuSelection) || this.menuSelection.length === 0 ||
      !this.menuSelection[0].customerType ||
      !this.menuSelection[0].customerType.minutesBetweenOrders) {
      return 0;
    }

    // Get the last order with a state of "PLACED"
    const lastPlaced = this.orders.filter((order) => order.state !== "SELECTING" && order.rows.find(item => ItemType.MENU_ITEM == item.itemType)).pop();
    if (!lastPlaced) {
      return 0;
    }

    // Calculate the cooldown period in seconds
    const coolDownPeriodInSeconds = this.menuSelection[0].customerType.minutesBetweenOrders * 60;

    // Split the order time into hours, minutes, and seconds
    const lastPlacedTimeSplit = lastPlaced.orderTime?.split(':').map(Number);

    // Calculate the moment the cooldown ends in seconds past midnight
    const momentOfCoolDownEnd = lastPlacedTimeSplit != undefined ? (lastPlacedTimeSplit[0] * 3600 + lastPlacedTimeSplit[1] * 60 + lastPlacedTimeSplit[2]) + coolDownPeriodInSeconds : 0;

    // Calculate the current time in seconds past midnight
    const currentTimeInSeconds = Math.floor(currentTime.getTime() / 1000) % 86400;

    // Calculate the remaining cooldown time in seconds
    const coolDownTimeInSeconds = momentOfCoolDownEnd - currentTimeInSeconds;

    // If the remaining cooldown time is less than 1 second or is NaN, return 0; otherwise, return the remaining cooldown time
    return coolDownTimeInSeconds < 1 || isNaN(coolDownTimeInSeconds) ? 0 : coolDownTimeInSeconds;
  }
}
