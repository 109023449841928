import Service from "./Service"
import Session from "@/model/Session"
import ObjectUtils from "@/utilities/ObjectUtils"
import Order from "@/model/Order"
import StorageService, { reconstructSession } from "./storage/StorageService"
import { Transaction, withStoreAsync } from "@/utilities/WithStore"
import Command from "~/model/Command"
import { reconstructOrders } from "./storage/StorageService";

export default class TableService extends Service {

  constructor(storageService: StorageService) {
    super(storageService)
  }

  async loadRemoteOrdersToSession(): Promise<void> {
    return withStoreAsync(this, async () => {
      let remoteOrders = await this._fetch(this.data_url + "/orders/" + this.storeData().session!!.apiKey) as Order[];
      ObjectUtils.fillReferences(remoteOrders)
      this.storeData().session!!.orders = remoteOrders;
      let index = 1;
      reconstructSession(this.storeData().session!!)
      this.storeData().session!!.orders.forEach((order) => {
        order.viewOrderNumber = index++;
      });
    }, Transaction.READ_WRITE)()
  }

  async getCurrentOrder() {
    return withStoreAsync(this, async () => {
      if (!this.storeData().session?.orders) {
        this.storeData().session!!.orders = []
      }

      // Find order in SELECTING state
      let newOrder = this.storeData().session?.orders.find((order) =>
        order.state === "SELECTING"
      )

      // If no SELECTING order exists, create new one
      if (!newOrder) {
        let sessionId = this.storeData().session?.apiKey
        let backendOrder = await this._fetch(this.data_url + `/orders/${sessionId}`, { method: "POST" })
        newOrder = Object.assign(new Order(), backendOrder) as Order

        if (this.storeData().session?.orders === undefined) {
          this.storeData().session!!.orders = []
        }

        newOrder.viewOrderNumber = (this.storeData().session?.orders.length || 0) + 1;
        this.storeData().session?.orders.push(newOrder)
      }

      return newOrder;
    }, Transaction.READ_WRITE)()
  }

  async requestTableStatus(locationName: string, tableNumber: string) {

    const command: Command = {
      commandType: 'READ_TABLE_STATE',
      locationName,
      json: tableNumber
    };

    try {
      const response = await this._fetch(this.data_url + "/commands", {
        method: "POST",
        body: JSON.stringify(command)
      });
      return response as Command;
    } catch (error) {
      console.error("Error sending command:", error);
      throw error;
    }
  }

  async sendCurrentOrder(order: Order, force: boolean = false): Promise<Order | undefined> {
    let deviceName = this.storageService.deviceName()
    order.deviceNames = deviceName
    try {
      let result = await this._fetch(this.data_url + `/orders?force=${force}`, {
        method: "POST",
        body: JSON.stringify(order)
      })
      return reconstructOrders([result])[0];
    } catch (e) {
      console.error(e)
    }
    return undefined;
  }

  async ordersOverview(maxOrders: number | undefined = undefined): Promise<any> {
    try {
      let result = await this._fetch(this.data_url + `/orders/overview${maxOrders != undefined ? '?maxOrders=' + maxOrders : ''}`, {
        method: "GET"
      })
      return result;
    } catch (e) {
      console.error(e)
    }
    return {};
  }

  async updateOrderstate(order: Order, state: string) {
    return this._fetch(this.data_url + `/orders/${order.number}/statusupdate`, {
      method: 'PUT',
      body: {
        "newState": state,
        "message": "Updating from webinterface."
      }
    });
  }

  async closeOrderAndCreateNew(orderToClose: Order) {
    return withStoreAsync(this, async () => {
      let existingOrder = this.storeData().session?.orders.find((order) => {
        return order.number === orderToClose.number
      });
      if (existingOrder) {
        existingOrder.state = "PLACED";
      }
      return await this.getCurrentOrder();
    }, Transaction.READ_WRITE)()
  }

  totalCurrentOrder() {
    let currentOrder = this.storeData().session?.orders.find((order) => order.state === "SELECTING")
    let total = 0
    currentOrder?.rows.forEach((row) => {
      total += row.numberOfItems
    })
    return total
  }

  async assignMenuSelection(session: Session) {
    return withStoreAsync(this, async () => {
      const rawSession = toRaw(session)
      let body = rawSession.menuSelection.map((menuSelection) => {
        menuSelection.customerType.numberSelected = menuSelection.quantity
        return menuSelection.customerType
      })
      return this._fetch(this.data_url + "/sessions/" + session.apiKey + "/menu_selection", {
        method: "POST",
        body: body,
        signal: AbortSignal.timeout(2000)
      })
    })()
  }

  askForBill() {
    withStoreAsync(this, async () => {
      let session = this.storeData().session
      let request = {
        tableNumber: session?.deviceConfiguration?.tableNumber,
        locationName: session?.deviceConfiguration?.locationName,
        paymentType: "CASH"
      }
      return await this._fetch(this.data_url + "/commands/requestBill", {
        method: "POST",
        body: request
      })
    })()
  }
}
