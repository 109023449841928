import { services } from "@/plugins/installServices";

export default defineNuxtRouteMiddleware(async (to, from) => {
  // What ever the current state of the application, we will replace the session when this url is called.
  if (to.path.startsWith("/client/session/")) {
    const sessionId = to.path.substring("/client/session/".length);
    const authorizationService = services.authorizationService();
    const session = await authorizationService.retrieveSession(sessionId);
    if (session !== undefined) {
      return navigateTo("/client/welcome")
    }
  } else {
    let data = services.storageService().data()
    if (!to.path.includes('newsession') && data && data.session === undefined || data?.session?.apiKey === null) {
      if (to.path !== "/") {
        return navigateTo("/")
      }
    }
  }
});
